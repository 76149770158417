import React from 'react';
import {Box, Text, Button, Center, VStack, HStack, NativeBaseProvider } from 'native-base';
import { useNavigate } from 'react-router-dom';

function Instrucoes() {

  const navigate = useNavigate();

  return (
    
    <>
      <Box 
            safeArea 
            flex={1} 
            minHeight="100vh" 
            width="100%" 
            bg="black" 
            alignItems="center" 
            justifyContent="center"
        >

        <Text color="white" fontSize="xl" mb="4" textAlign="center" >Instruções sobre a Tokenização de Imóvel</Text>

        <Text color="white" fontSize="md" mb="4" >
          Este projeto exemplifica a tokenização de um imóvel, dividindo-o em 10 partes iguais chamadas VAI-Vale-Imovel.
        </Text>

        <Text color="white" fontSize="md" mb="4">
          Cada token: VAI, representa 10% do imóvel. 
        </Text>
    
        <Text color="white" fontSize="md" mb="4">
          Para adquirir 1 VAI, você precisa trocá-lo por 1 LINK da rede Sepolia Testnet.
        </Text>

        <Text color="white" fontSize="md" mb="4">
          Adicione a rede sepolia na Metamask:
        </Text>  

        <Text color="white" fontSize="md" mb="4" ml="16">
          a) Incluir manualmente:<br></br>

          Nome da rede:<br></br>
          Sepolia <br></br>
          Default RPC URL:<br></br>
          <br></br>
          Infura<br></br>
          sepolia.infura.io<br></br>
          <br></br>
          ID da cadeira<br></br>
          11155111<br></br>
          <br></br>
          Simbolo da moeda:<br></br>
          SepoliaETH<br></br>
          <br></br>
          URL do Block Explorer:<br></br>
          sepolia.etherscan.io<br></br>
          <br></br>
          <br></br>

          b) Incluir pelo site ChainList:<br></br>
          chainlist.org <br></br>
          <br></br>
          Search Networks: sepolia<br></br>
          Marcar: Include Testnets<br></br>
          Identificar ChainID 11155111 
          Add to Metamask<br></br>

        </Text>


        <Text color="white" fontSize="md" mb="4" mt="4">
        O que é Faucet ? Faucets são serviços que distribuem tokens gratuitos para você testar a rede.
        </Text>
        
        <Text color="white" fontSize="md" mb="4">
        Todas as transações aqui são feitas com tokens obtidos via faucet.
        </Text> 

        <Text color="white" fontSize="md" mb="4">
        Obter Faucet: https://faucets.chain.link/sepolia<br></br>
        Ethereum Sepolia => 0.5 Ethereum<br></br>
        Ethereum Sepolia => 250 LINK<br></br>
        <br></br>
        Atenção:<br></br> 
        Voce vai precisar ter 1 LINK on Ethereum Mainnet para obter os tokens nativos.<br></br>
        </Text>

        <Text color="white" fontSize="xl" mb="4" mt="12" textAlign="center">
        Adicionar o token deste contrato na metamask:  ValeImovel (VAI)<br></br> 
        Address: 0x3eC0DC35Fd64c5357F637603A75EAcf1DDc8aF4a<br></br>
        <br></br>
        https://sepolia.etherscan.io/token/0x3eC0DC35Fd64c5357F637603A75EAcf1DDc8aF4a
        </Text>

        
        <Center>
          <Button colorScheme="teal" w="40" onPress={() => navigate(-1)}>Voltar</Button> 
        </Center>

      </Box>

      

    </>
    
  );
}

export default Instrucoes;  